.service-container {
    background-color: black;
    padding: 35px;
    text-align: center;
    
  }
  .diff-container {
    background-color: #124e90;
    padding: 35px;
    text-align: center;
  }
  .heading {
    font-size: 45px;
    color: white;
    font-weight: bold;
    padding-bottom: 20px;
  }
  .description {
    color: white;
    padding: 20px;
    align-content: center;
    font-size: 18px;
      }
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .card-hovered {
    animation: bounce 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-25px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  @media (max-width: 767px) {
    .card {
      width: 100%;
      max-width: none;
    }
  
    .heading {
      font-size: 35px;
    }
    .description {
        font-size: 16px;
      }
  }
  .service-avatar-container {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }
  
  /* Avatar image styles */
  .avatar {
    width: 100px; /* Adjust the width as needed */
    height: 100px; /* Adjust the height as needed */
    border-radius: 50%;
    object-fit: cover;
  }