/* Contact.css */

.contact-container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form {
    width: 50%;
    padding: 7px;


    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2); /* Box shadow properties */

  }
  
  .contact-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .contact-form .inputField,
  .contact-form .textareaField {
    width: 94%;
    padding: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    border: 1px solid #d3cece;
    font-size: 16px;
  }
  
  .contact-form .textareaField {
    resize: vertical;
  }
  
  .contact-form .button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .contact-infos {
    width: 48%;
    background-color: #6c279c;
    border-radius: 10px;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2); /* Box shadow properties */
  }
  
  .contact-infos h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #f7f7f7;
  }
  
  .info-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 150px;
    color: #f7f7f7;

  }
  .info-item span {
    font-weight: bold;
    margin-left: 10px;
    color: #f7f7f7;

  }
  .info-item p {
    margin-left: 10px;
    color: #f7f7f7;

  }
  .buttonBlue {
    background-color:  #6c279c;
    color: white;
    margin-left: 71%;
  }  
  .buttonBlue:hover {
    background-color: 
    #6203a5;
  }
  
  .buttonGreen {
    background-color: #2ecc71;
    color: white;
  }
  
  .buttonGreen:hover {
    background-color: #27ae60;
  }
  @media (max-width: 768px) {
    .contact-container {
      flex-direction: column;
    }
  
    .contact-form,
    .contact-infos {
      width: 100%;
      padding: 5px;
    }
  
    .contact-infos{
      margin-top: 20px; 
    }
    .info-item {
        display: flex;
        margin-bottom: 10px;
        margin-left: 10px;
      }
      .info-item span {
        margin-left: 5px;
      }
      .buttonBlue {
        background-color:  #6c279c;
        color: white;
        margin-left: 23%;
      } 
      .contact-form .inputField,
  .contact-form .textareaField {
    width: 90%;
    padding: 10px;
    margin-bottom: 12px;
    border: 1px solid #d3cece;
    font-size: 16px;
  }
  }