.pricing-container {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: black;
  }
  
  .pricing-card {
    width: 300px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  
    &:hover {
      transform: translateY(-5px);
    }
  }
  
  .pricing-title {
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
  
  .pricing-price {
    margin-bottom: 15px;
    font-size: 1.25rem;
  }
  
  .pricing-features {
    margin-bottom: 20px;
  }
  
  .pricing-features li {
    margin-bottom: 10px;
  }
  
  .select-plan-button {
    width: 100%;
  }
  