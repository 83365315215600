.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    height: 80vh;
  }
  

  .imageContainer {
    flex: 1;
  }
  
  .imageStyle {
    width: 65%;
    max-width: 100%; 
    height: auto; 
    padding-left: 220px;
    padding-top: 30px;
  }
  
  .textContainer {
    flex: 1;
    padding: 0 100px; 
    color: white;
    font-size: 15px;
    max-width: 800px;
  }
  
  .textContainer span {
    font-weight: bold;
    font-size: 38px;
  }

  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      height: auto;
    }
  
    .imageStyle {
      width: 80%;

      padding-left: 35px;
    }
  
    .textContainer {
      padding: 30px;
    }
  }
  