/* About.css */

.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px;
    background-color: #383a3d;
  }
  .another-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-height: 40vh;
    background-color: #fe742c;
  }
  .heading {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  .card:hover {
    background-color:  #134e90; 
  }
  .cardContainer {
    display: flex;
    justify-content: center;
  }
  
  .card {
    background-color: #fe8237;
    border: 0.2px solid black;
    padding: 20px;
    width: 220px;
    height: 160px;
    border-radius: 5px;
    text-align: center; 
  }
  .about-heading {
    font-size: 40px;
    color: white;
    font-weight: bold;
    padding-bottom: 20px;
  }
  .card h2 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .card p {
    font-size: 1em;
  }

/* Media query for smaller screens */
@media (max-width: 768px) {
  .about-heading {
    font-size: 30px;
    padding-bottom: 10px;
  }

  .cardContainer {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 80%;
    margin-bottom: 10px;
  }
}
