
.testimonial-card {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #000000;
  text-align: center;
  max-width: 300px;
  margin: 20px;
  flex: 1;
  
}
.testimonial-container {
  background-color: 
  #282828; /* Add your desired background color here */
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
/* Testimonial quote styles */
.quote {
  margin-bottom: 20px;
  font-size: 1.25rem;
  font-style: italic;
  color: white;
}

/* Testimonial information styles */
.info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name {
  font-size: 1.5rem;
  margin-bottom: 5px;
  color: #fe742c;
}

.position {
  font-size: 1rem;
  color: #777;
}

/* Testimonial container styles */
.testimonial-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.avatar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

/* Avatar image styles */
.avatar {
  width: 100px; /* Adjust the width as needed */
  height: 100px; /* Adjust the height as needed */
  border-radius: 50%;
  object-fit: cover;
}