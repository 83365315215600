.slide-container {
  display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffebe0;
    height: 100vh;
    padding-left:'80px';
 padding-top:'160px'
  
}

.slide-imageStyle {
  width:90%;
  padding-left: 65px;
}

.slider-text-container {
  position: absolute;
  top: 60%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 45%;
  box-sizing: border-box;
  text-align: left;
}

.slider-heading {
  font-weight: bold;
  font-size: 4vw;
  color: #fe8237;
}

.slider-subheading {
  font-weight: bold;
  font-size: 4vw;
}

.slider-text {
  font-size: 2vw;
}
.slide-imageStyle {
  width: '45%';
  height: 'auto';
  float: 'left'
}
.slider-button {
  background-color: #fe8237;
  color: white;
  border: none;
  padding: 1.5vw 3vw;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 2vw;
}
.imageContainer {
  flex: 1;
}
.text-container{
  flex: 1;
    padding: 0 10px; 
    font-size: 15px;
}
@media (max-width: 768px) {
  .slide-container {
    flex-direction: column;
    align-items: center;
  }
  .slide-imageStyle {
    width:100%;
    padding-left: 2px;
margin-top: 120px;  }
  .text-container {
    padding: 10px;
    text-align: center;
  }
}