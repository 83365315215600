

.footer {
    background-color: #222222;
    color: whitesmoke;
    padding: 25px 0 3px 0;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
  }
  
  .footer-section {
    flex: 1;
    margin: 0 25px;
  }
  
  .footer-section h3 {
    color: #ffffff;
    margin-bottom: 25px;
    font-size: 24px;
    margin-top: 0; 
  }
  
  .footer-section p {
    margin-top: 15px; 
    font-size: 15px;
  }
  
  
  .footer-section a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }
  
  .footer-section a:hover {
    color: #c94e29;
  }
  
  .footer-bottom {
    background-color: black;
    padding: 20px 0;
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
  }
  
  .footer-bottom p {
    margin: 0;
  }
  
  .footer-icons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer-icons a {
    margin: 0 10px;
    text-decoration: none;
    color: #555;
  
  }
  
  .footer-icons a:hover {
    color: #c94e29;
  }
  
  .footer-icons svg {
    width: 30px;
    height: 30px;
  }
  .fa-x-twitter{
font-size: 28px;  }
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-section {
      margin: 0;
      margin-bottom: 20px;
      text-align: center;
    }
  }
  