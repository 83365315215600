
.navbar {
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #242222;
    color: #fff;
    border-bottom: 1px solid #555;
    width: 99%;
  }
  
  .navbar-brand {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  
  .navbar-title {
    font-size: 20px;
  }
  
  .menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .menu-icon .bar {
    height: 3px;
    width: 25px;
    margin: 4px 0;
    background-color: #fff;
    transition: transform 0.3s ease;
  }
  
  .navbar-links {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .navbar-links li {
    margin: 0 15px;
  }
  
  .navbar-links a {
    color: #fff;
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .navbar-links a:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  /* Navbar animations and transitions */
  .menu-icon.open .bar:nth-child(1) {
    transform: translateY(9px) rotate(45deg);
  }
  
  .menu-icon.open .bar:nth-child(2) {
    opacity: 0;
  }
  
  .menu-icon.open .bar:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
  }
  .navbar-links {
    margin-left: auto;
  }
  @media (max-width: 768px) {
    
    .menu-icon {
      display: flex;
      margin-right: 40px;
    }
  
    .navbar-links {
      display: none;
      flex-direction: column;
      width: 100%;
    }
  
    .navbar-links.open {
      display: flex;
      margin-right: 28px;
    }
  
    .navbar-links li {
      margin: 1px 0;
    }
  }
  